@charset "utf-8";
@use '../foundation/mixin' as m;


// $oswald: 'Oswald', 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$oswald: 'Oswald', sans-serif;

.c-fontOswald {
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
}
.c-fontPTSans {
  font-family: 'PT Sans', sans-serif;
}

