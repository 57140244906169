@charset "utf-8";
.c-ofiCover {
  object-fit: cover;
  font-family: "object-fit: cover;, object-position: center;";
  width: 100%;
  height: 100%;
  object-position: center;
}
.c-ofiContain {
  object-fit: contain;
  font-family: "object-fit: contain;, object-position: center;";
  width: 100%;
  height: 100%;
  object-position: center;
}
