@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;


.l-common{
    &__contact{
        background-image:  url(../images/common/bg01.svg);
        background-position:  top left;
        background-repeat: repeat;
        background-size: 15vw;
        background-color: c.$subBlue;
        &__wrapper{
            background: url(../images/common/footer_contact_img.png) top right/contain no-repeat;
            padding: {
                top:min(100px,8vw);
                bottom:min(100px,8vw);
            };
            @include m.mq(sm) {
                width: 100%;
                background-position-x: 50px;
                background-size: cover;
            }
        }
        &__ttl{
            font-size: min(5.0rem,4vw);
            font-weight: 700;
            letter-spacing: 0.1em;
            line-height: 1.3;
            background:linear-gradient(transparent 60%, #ff0 60%);
            display: inline;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: c.$mainColor;
            -webkit-text-fill-color: white;
            @include m.mq(tab) {
                -webkit-text-stroke-width: 1px;
            }
            @include m.mq(sm) {
                font-size: min(5.0rem,6vw);
            }
        }
        &__txt{
            color: #fff;
            font-size: min(2rem,2.4vw);
            letter-spacing: 0.1em;
            line-height: 1.5;
            margin-top: 2em;
            @include m.mq(sm) {
                font-size: min(2rem,3vw);
            }
        }
        &__btn{
            margin-top: 20px;
            max-width: 400px;
            font-size: min(2.4rem,3vw);
            @include m.mq(sm) {
                font-size: min(2.4rem,4vw);
            }
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 3.5em;
                background-color: c.$mainColor;
                color: #fff;
                letter-spacing: 0.1em;
                position: relative;
                border-radius: 100px;
                transition: 0.3s;
                &:hover{
                    background-color: lighten(c.$mainColor, 10%);
                    opacity: 1;
                }
                &::before{
                    content: "";
                    width: 1em;
                    height: 5px;
                    background-color: #fff;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    position: absolute;
                    top: calc(50% + 3px);
                    right: calc(5% - 1px);
                    @include m.mq(sm) {
                        height: 3px;
                        top: calc(50% + 2px);
                    }
                }
                &::after{
                    content: "";
                    display: block;
                    width: 0.65em;
                    height: 0.65em;
                    border-bottom: 5px solid #fff;
                    border-right: 5px solid #fff;
                    transform: rotate(-45deg) translateY(-50%);
                    position: absolute;
                    top: 50%;
                    right: 3%;
                    @include m.mq(sm) {
                        border-width: 3px;
                    }
                }
                
            }
        }
    }//__contact
}//__common

.l-footer{
    background-color: #F5F5F5;
    &__wrapper{
        padding: {
            top: 50px;
            bottom: 20px;
        };
    }
    &__top{
        border-bottom: 3px solid c.$mainColor;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include m.mq(tab-l) {
            flex-direction: column;
        }
        &__main{
            display: flex;
            align-items: baseline;
            @include m.mq(tab-l) {
                flex-direction: column;
                align-items: center;
            }
        }
        &__logo{
            @include m.mq(tab) {
                max-width: 180px;
            }
        }
        &__sns{
            display: flex;
            margin-left: 30px;
            @include m.mq(tab-l) {
                margin: 20px auto;
            }
            > li{
                margin-right: 10px;
                width: 30px;
                @include m.mq(tab-l) {
                    width: 44px;
                }
            }
        }
        &__info{
            display: flex;
            width: 70%;
            @include m.mq(tab-l) {
                width: 100%;
            }
            @include m.mq(tab) {
                flex-direction: column;
                align-items: center;
                max-width: 500px;
                margin: 0  auto;
            }
            > li{
                width: calc(100% / 3);
                padding: 0 3%;
                @include m.mq(tab) {
                    width: 100%;
                }
                &:not(:last-child){
                    border-right: 1px solid #707070;
                    @include m.mq(tab) {
                        border-right: none;
                        margin-bottom: 10px;
                    }
                }
            }
            &__cont{
                dt{
                    background-color: c.$mainColor;
                    color: #fff;
                    font-size: 1.4rem;
                    letter-spacing: 0.1em;
                    padding: 0.2em;
                    text-align: center;
                    font-weight: 700;
                    @include m.mq(tab) {
                        font-size: 1.8rem;
                    }
                }
                dd{
                    font-size: min(1.4rem, 1vw);
                    letter-spacing: 0.1em;
                    line-height: 1.2;
                    margin-top: 5px;
                    @include m.mq(tab-l) {
                        font-size: min(1.4rem, 1.5vw);
                    }
                    @include m.mq(tab) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }//__top
    &__bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        @include m.mq(tab-l) {
            flex-direction: column;
            align-items: center;
        }
        &__nav{
            display: flex;
            justify-content: space-between;
            width: 70%;
            @include m.mq(tab-l) {
                width: 100%;
            }
            @include m.mq(tab) {
                flex-direction: column;
                max-width: 500px;
                &__block{
                    &:not(:last-child){
                        margin-bottom: 30px;
                    }
                }
            }
            
            &__ttl{
                font-size: 1.8rem;
                letter-spacing: 0.1em;
                font-weight: 700;
                display: flex;
                align-items: center;
                position: relative;
                &::before{
                    content: "";
                    display: block;
                    width: 1em;
                    height: 1em;
                    border-radius: 100px;
                    background-color: c.$mainColor;
                    margin-right: 0.5em;
                }
                &::after{
                    content: "";
                    display: block;
                    width: 0.3em;
                    height: 0.3em;
                    border-bottom: 2px solid #fff;
                    border-right: 2px solid #fff;
                    transform: rotate(-45deg) translateY(-50%);
                    position: absolute;
                    top: 50%;
                    left: 0.35em;
                }
            }
            &__list{
                margin: 10px 0 0 10px;
                >li{
                    font-size: 1.5rem;
                    letter-spacing: 0.1em;
                    @include m.mq(tab) {
                        border-bottom: 1px dotted #707070;
                    }
                    &:not(:last-child){
                        margin-bottom: 0.5em;
                        @include m.mq(tab) {
                            margin-bottom: 0;
                        }
                    }
                    a{
                        display: flex;
                        align-items: center;
                        @include m.mq(tab) {
                            padding: 0.5em 0;
                        }
                        &::before{
                            content: "";
                            display: block;
                            width: 0.5em;
                            height: 0.5em;
                            border-bottom: 1px solid c.$mainColor;
                            border-right: 1px solid c.$mainColor;
                            transform: rotate(-45deg);
                            margin: 0.5em;
                        }
                    }
                }
            }   
        }//__nav
        &__btnBox{
            max-width: 250px;
            width: 25%;
            @include m.mq(tab-l) {
                max-width: 300px;
                width: 100%;
                margin-top: 40px;
            }
           
        }
    }//__bottom
    &__addArea{
        margin-top: 50px;
    }
    &__regist{
        text-align: center;
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        line-height: 1.4;
    }
    &__copyright{
        background-color: c.$mainColor;
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
        letter-spacing: 0.1em;
        padding: 0.5em;
        display: block;
        @include m.mq(tab) {
            padding-bottom: 22vw;
        }
    }
}//__footer

.l-pageTop{
    position: fixed;
    right: 3%;
    bottom: 3%;
    z-index: 1000;
    @include m.mq(tab) {
        bottom: 22vw;
    }
    >a{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: min(1.4rem,3vw);
        width: 5em;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background: c.$mainColor;
        color: #fff;
        position: relative;
        line-height: 1.0;
        letter-spacing: 0.3em;
        text-align: center;
        font-weight: 700;
        &:hover{
            background-color: lighten(c.$mainColor, 10%);
            opacity: 1;
        }
        &::before{
            content: "";
            display: block;
            padding-bottom: 113%;
        }
    }
    span{
        padding-top: 0.4em;
        &::after{
            content: "";
            display: block;
            width: 1.0em;
            height: 1.0em;
            border-top: 0.4em solid #fff;
            border-right: 0.4em solid #fff;
            transform: rotate(-45deg);
            margin: 0.5em auto 0;
        }
    }
}//__pageTop