@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;
@use '../component/_c-font' as f;

.l-main {
  overflow: hidden;
  padding-bottom: 150px;
  @include m.mq(sm) {
    padding-bottom: 70px;
  }
  &:not(.p-top){
    margin-top: 70px;
    @include m.mq(sm) {
      margin-top: 40px;
    }
    section[class^="p-"]{
      &:not(:last-of-type){
        margin-bottom: 150px;
        @include m.mq(sm) {
          margin-bottom: 70px;
        }
      }
    }
  }
}

.l-pageTtl{
  background-color: #eee;
  position: relative;
  &::before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../images/common/bg02.svg) repeat top left/2vw;
    mix-blend-mode: soft-light;
    @include m.mq(sm) {
      background-size: 5vw;
    }
  }
  &__wrapper{
    padding: 2em 0;
    font-size: 3.0rem;
    display: flex;
    align-items: center;
    @include m.mq(sm) {
      padding: 1.5em 0;
      font-size: 2.4rem;
    }
  }
  &__ttl{
    display: flex;
    flex-direction: column;
    .en{
      font-family: f.$oswald;
      letter-spacing: 0.4em;
      color: c.$mainColor;
      font-weight: 600;
      font-size: max(0.4em,10px);
    }
    .ja{
      font-weight: 700;
      letter-spacing: 0.1em;
    }
  }
}

.l-breadcrumbs{
  margin: {
    top: 10px;
    bottom: 120px;
  }
  @include m.mq(tab-l) {
    margin-bottom: clamp(50px,10vw,150px)
  }
  &__wrapper{
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    >li{
      margin: 0 0.5em;
      white-space: nowrap;
      &::marker{
        font-size: 0;
      }
    }
  }
}