@charset "utf-8";
@use 'mixin' as m;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  letter-spacing: inherit;
  line-height: inherit;
  box-sizing: border-box;
  color: inherit;
  -webkit-appearance: none;
  // @include m.mq(tab) {
  //   line-height: 1.65;
  // }
}
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
html {
  width: 100%;
  /* ルートのフォントサイズを10pxに設定 */
  font-size: 62.5%;
  @include m.mq(tab-l) {
    font-size: 61.5%;
  }
  @include m.mq(tab) {
    font-size: 54.6%;
  }
  &.fadeout {
    opacity: 0;
  }
}

body {
  margin: 0px;
  overflow: hidden;
  /* ルートのフォントサイズを1.6em（16pxと同等のサイズ）に設定 */
  font-size: 1.6em;
  font-weight: 500;
  color: #333;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

select,
option,
span {
  font-weight: 500;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;

  span {
    font-weight: inherit;
    font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    }
}

a {
  // color: #000;
  text-decoration: none;
  transition: 0.4s;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  background-color: inherit;
  color: inherit;
  &:hover{
    opacity: 0.7;
  }
}
p {
  font-weight: 500;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}
span {
  font-size: inherit;
  font-weight: inherit;
}
figure{
  margin: 0;
}
input[type="text"],
textarea {
  -webkit-appearance: none;
  border: none;
  font-family: sans-serif;
}
textarea {
  resize: none;
  font-family: sans-serif;
}
button,
select,
input,
a {
  @include m.mq(tab-l) {
    -webkit-tap-highlight-color: transparent;
  }
}
button,
select,
input,
input[type="submit"] {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  font-weight: 500;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

select::-ms-expand {
  display: none;
}

img {
  // display: block;
  width: 100%;
}

section {
  position: relative;
}
