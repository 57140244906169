@charset "utf-8";
// 色を変数化して保管


$mainColor : #3370C7;
$subBlue : #A0C3E9;
$subYellow : #FFF000;

$blue : $mainColor;
$yellow : #DED70F;
$green : #39A143;
$red : #D64E2B;