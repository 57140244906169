@charset "utf-8";
@use '../foundation/mixin' as m;

.u-spNone {
  display: block;
  @include m.mq(sm) {
    display: none;
  }
}
.u-spBlock {
  display: none;
  @include m.mq(sm) {
    display: block;
  }
}


.u-footer{
  &__tel{
    text-align: center;
    font-size: min(2.8rem, 1.8vw);
    white-space: nowrap;
    display: block;
    font-weight: 700;
    @include m.mq(tab-l) {
      font-size: min(2.8rem, 2.9vw);
    }
    @include m.mq(sm) {
      font-size: min(3.8rem, 6.9vw);
    }
  }
  &__runtime{
    text-align: center;
    &__main{
      font-size: min(2.6rem, 2.0vw);
      white-space: nowrap;
      display: block;
      font-weight: 700;
      @include m.mq(tab-l) {
        font-size: min(2.6rem, 3vw);
      }
      @include m.mq(sm) {
        font-size: min(3.6rem, 7vw);
      }
    }
    &__sub{
      font-size: 1.0rem;
      letter-spacing: 0.1em;
      color: #AFAFAF;
      line-height: 1;
      display: block;
      @include m.mq(sm) {
        font-size: 1.2rem;
      }
    }
  }
}