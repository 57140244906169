@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;
@use '../component/c-animation' as a;

body{
    &.is-navOpen{
        .l-header{
            &__hamberger{
                @include m.mq(tab-l) {
                    height: 30px;
                    > span{
                        &:first-child{
                            transform: rotate(45deg);
                        }
                        &:last-child{
                            transform: rotate(-45deg);
                        }
                    }
                    
                }
                @include m.mq(sm) {
                    height: 24px;                    
                }
            }//__hamberger
            &__gnav{
                &__wrapper{
                    @include m.mq(tab-l) {
                        left: 0;
                    }
                }
            }//__gnav
        }
    }
}

.l-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    min-height: 70px;
    @include m.mq(sm) {
        min-height: 40px;
    }
    &__hamberger{
        display: none;
        @include m.mq(tab-l) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40px;
            height: 15px;
            margin: auto 0 auto 3vw;
            cursor: pointer;
            transition: 0.3s;
            position: relative;
            z-index: 10001;
            &:hover{
                animation: pulse 0.5s ease infinite;
                opacity: 1;
            }
            @include m.mq(sm) {
                width: 32px;

            }
            > span{
                display: block;
                width: 100%;
                height: 2px;
                background-color: #333;
                transform-origin: left;
            }
            &.js-open{
                height: 30px;
                margin: auto 0 auto 20px;
                > span{
                    &:first-child{
                        transform: rotate(45deg);
                    }
                    &:last-child{
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    &__logo{
        text-align: left;
        padding: 0.5em 1em;
        @include m.mq(tab-l) {
            position: absolute;
            left: 50%;
            transform: translate(-50%,-50%);
            top: 50%;
        }
        &__cap{
            font-size: 1.2rem;
            letter-spacing: 0.15em;
            line-height: 1;
            display: block;
            @include m.mq(tab-l) {
                display: none;
            }
        }
        img{
            max-width: 240px;
            display: block;
            @include m.mq(sm) {
                width: 60%;
                margin: auto;
            }
        }
    }
    &__block{
        display: flex;
    }
    &__gnav{
        display: flex;
        align-items: stretch;
        &__wrapper{
            
            @include m.mq(tab-l) {
                position: fixed;
                left: -100%;
                top: 0;
                bottom: 0;
                background-color: rgba(255,255,255,0.95);
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: baseline;
                transition: 0.3s;
                z-index: 10000;
                overflow-y: auto;
                &.js-open{
                    left: 0;
                }
            }
            
        }
        &__list{
            display: flex;
            align-items: center;
            height: 100%;
            @include m.mq(tab-l) {
                flex-direction: column;
                justify-content: center;
                align-items: baseline;
                height: auto;
                margin-top: max(20vw,80px);
                a[class$=__link]{
                    cursor: pointer;
                    position: relative;
                    width: 100%;
                    &::after{
                        content: "";
                        display: block;
                        width: 0.5em;
                        height: 0.5em;
                        border-bottom: 2px solid c.$mainColor;
                        border-right: 2px solid c.$mainColor;
                        transform: rotate(-45deg) translateY(-50%);
                        position: absolute;
                        top: 50%;
                        right: 0.8em;
                        transition: 0.5s;

                    }
                    &:hover{
                        opacity: 1;
                        &::after{
                            right: 0.2em;
                        }
                    }
                }
            }
            > li{
                position: relative;
                display: flex;
                height: 100%;
                font-size: min(1.8rem,1.2vw);
                &:not(:first-child){
                    margin-left: 2em;
                }
                @include m.mq(tab-l) {
                    height: auto;
                    width: min(900px,90%);
                    font-size: min(2.4rem,3.2vw);
                    margin: 0em auto !important;
                    border-bottom: 1px solid #707070;
                }
            }
        }
        &__link{
            display: flex;
            align-items: center;
            font-weight: 700;
            letter-spacing: 0.1em;
            line-height: 1.5;
            @include m.mq(tab-l) {
                padding: 0.8em ;
            }
            &::before{
                content: "";
                display: block;
                width: 0.5em;
                height: 0.5em;
                border-bottom: 2px solid c.$mainColor;
                border-right: 2px solid c.$mainColor;
                transform: rotate(-45deg);
                margin: 0.5em;
                transition: 0.5s;
                @include m.mq(tab-l) {
                    display: none;
                }
            }
            &:hover{
                opacity: 1;
                &::before{
                    transform: rotate(-45deg) translate(0.25em, 0.25em);
                }
            }
        }
        &__children{
            opacity: 0;
            visibility: hidden;
            // height: 0;
            overflow: hidden;
            position: absolute;
            top: 100%;
            background: #fff;
            padding: 10px 1em;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            z-index: 10001;
            transition: 0.3s ;
            @include m.mq(tab-l) {
                position: static;
                opacity: 1;
                visibility: visible;
                box-shadow: none;
                background-color: #5e5d5d0f;
                border-top: 1px dotted #707070;
                width: 100%;
                padding-top: 0;
                padding-bottom: 0;
            }
            &__wrapper{
                > li{
                    
                    &:not(:last-child){
                        margin-bottom: 0.5em;
                        @include m.mq(tab-l) {
                            margin-bottom: 0 !important;
                            border-bottom: 1px solid #707070;
                        }
                    }
                }
            }
            &__link{
                display: flex;
                align-items: center;
                font-size: min(1.8rem,1.5vw);
                @include m.mq(tab-l) {
                    padding: 0.8em;
                    font-size: max(1.5rem,2vw);
                }
                &:hover{
                    opacity: 1;
                    .l-header__gnav__children__link__inner{
                        &::after{
                            width: 100%;
                        }
                    }
                }
                &__inner{
                    letter-spacing: 0.1em;
                    line-height: 1.5;
                    white-space: nowrap;
                    position: relative;
                    &::after{
                        content: "";
                        display: block;
                        width: 0;
                        height: 0.5em;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: c.$mainColor;
                        z-index: -1;
                        transition: 0.5s;
                        @include m.mq(tab-l) {
                            display: none;
                        }
                    }
                }
            }
        }
        &__parent{
            display: flex;
            align-items: center;
            cursor: pointer;
            @include m.mq(tab-l) {
                flex-direction: column;
                width: 100%;
                align-items: baseline;
                cursor: initial;
            }
            &:hover{
                // opacity: 0;
                opacity: 1;
                .l-header__gnav__children{
                    opacity: 1;
                    visibility: inherit;
                    // height: auto;

                }
            }
            
        }
    }
    &__btnBox{
        display: none;
        @include m.mq(tab-l) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 40px;
            &::before{
                content: "お問い合わせ";
                display: block;
                font-size: min(4rem,6vw);
                letter-spacing: 0.1em;
                font-weight: 700;
                width: 100%;
                text-align: center;
                color: #fff;
                background-color: #999;
                margin-bottom: 1em;
                line-height: 1;
                padding: 0.5em;
            }
            > li{
                width: 48%;
                margin-bottom: 0 !important;
                &:nth-child(2) ~ li{
                    margin-top: 4%;
                }
            }
        }
        @include m.mq(sm) {
            > li{
                width: 100%;
                // margin-bottom: 0;
                &:nth-child(1) ~ li{
                    margin-top: 4%;
                }
            }
        }
    }
    &__btn{
        margin-left: 2em;
        @include m.mq(tab) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 5px;
            border: 1px solid #ccc;
        }
        a{
            background: c.$mainColor url(../images/common/btn_bg.svg) center/cover no-repeat;
            padding: .7em 3em .7em .5em;
            font-size: min(1.6rem,1.2vw);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            &:hover{
                background-color: lighten(c.$mainColor, 10%);
                opacity: 1;
            }
            @include m.mq(tab) {
                font-size: min(3.2rem,4.2vw);
                padding: 0.7em 0.3em;
            }
            &::after{
                content: "";
                display: block;
                width: 1em;
                height: 1em;
                border-bottom: 4px solid #fff;
                border-right: 4px solid #fff;
                position: absolute;
                transform: rotate(-45deg) translateY(-50%);
                top: 50%;
                right: 1em;
            }
        }
        &__cap{
            color: #fff;
            display: block;
            letter-spacing: 0.15em;
            line-height: 1.5;
        }
        &__txt{
            color: c.$subYellow;
            display: block;
            font-size: 1.3em;
            font-weight: 700;
            letter-spacing: 0.1em;
            line-height: 1;
        }
    }
}