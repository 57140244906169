@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;

.c-obj{
    &__beeNest{
        width: 1em;
        height: 0.6em;
        background: c.$mainColor;
        position: relative;
        margin-right: 0.5em;
        > span{
            height: 100%;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
        }
        &::before,
        &::after {
        content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: c.$mainColor;
            z-index: 0;
        }
        &::before {
            transform: rotate(60deg);
        }
        &::after {
            transform: rotate(-60deg);
        }
    }
    &__hexagon{
        font-size: 2.8rem;
        // width: 150px;
        // width: 15em;
        width: inherit;
        // height: 130px; /* width*√3/2 */
        // height: 13em; /* width*√3/2 */
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background: c.$mainColor;
        position: relative;
        &::before{
            content: "";
            display: block;
            padding-bottom: 113%;
        }
        &::after{
            content:"";
            display: block;
            // width: calc(100% + 2px);
            // height: calc(100% + 2px); /* width*√3/2 */
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);;
            background: #ffffff;
            position: absolute;
            top: 1.5%;
            left: 1.5%;
            right: 1.5%;
            bottom: 1.5%;
            z-index: -1;
        }
    }
}