@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;
@use 'c-font' as f;

.c-ttl{
    &01{
        text-align: center;
        font-size: min(6.0rem, 4.9vw);
        font-weight: 600;
        letter-spacing: 0.1em;
        color: c.$mainColor;
        line-height: 1.2;
        @include m.mq(sm) {
            font-size: min(8.0rem, 7.7vw);
        }
        span{
            display: inline-block;
            border-bottom: 5px solid c.$mainColor;
            padding-bottom: 0.2em;
        }
    }

    &02{
        text-align: left;
        font-size: min(6.0rem, 8vw);
        .en{
            font-size: 0.25em;
            letter-spacing: 0.4em;
            line-height: 1.2;
            color: #B5B5B5;
            display: block;
            font-family: f.$oswald;
            font-weight: 600;
        }
        .ja{
            color: c.$mainColor;
            font-size: 1em;
            letter-spacing: 0.1em;
            line-height: 1.3;
            display: block;
            font-weight: 700;
        }
    }
    &03{
        border-bottom: 2px solid #D8D8D8;
        font-size: 2.0rem;
        letter-spacing: 0.1em;
        line-height: 1.4;
        font-weight: 700;
        > span{
            position: relative;
            padding: 0.3em 0;
            display: inline-block;
            &::after{
                content: "";
                display: block;
                width: 110%;
                height: 2px;
                background-color: c.$mainColor;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: -0.5em;
            }
        }
    }
    &04{
        border-left: 5px solid c.$mainColor;
        padding: 0.1em 0.5em;
        font-size: 1.8rem;
        letter-spacing: 0.1em;
        line-height: 1.5;
        text-align: left;
        font-weight: 700;
    }
}

