@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;


.c-sp__none{
    display: initial;
    @include m.mq(sm) {
        display: none;
    }
}
.c-fw{
    &__bold{
        font-weight: 700 !important;
    }
    &__mid{
        font-weight: 500 !important;
    }
}

.c-fs{
    &__small{
        font-size: 0.7em !important;
    }
    &__large{
        font-size: 1.5em !important;
    }
}

.c-fc{
    &__blue{
        color: c.$blue !important;
    }
    &__red{
        color: c.$red !important;
    }
    &__yellow{
        color: c.$yellow !important;
    }
    &__green{
        color: c.$green !important;
    }
}

.c-note{
    text-align: center;
    margin: 50px auto;
    letter-spacing: 0.1em;
}

.c-ta{
    &__c{
        text-align: center;
    }
    &__l{
        text-align: left;
    }
    &__r{
        text-align: right;
    }
}

.c-txt{
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    color: inherit;
    line-height: inherit;
    margin-bottom: 1.5em;
    &:last-of-type{
        margin-bottom: 0;
    }
    &Box{
        font-size: 2.0rem;
        line-height: 1.5;
        letter-spacing: 0.1em;
        @include m.mq(sm) {
            font-size: 1.6rem;
        }
        // color: #333;
    }
}

.c-txt__dot {
    padding-top: .2em;
    background-position: top left -0.05em;
    background-repeat: repeat-x;
    background-size: 1.1em .3em;
    background-image: radial-gradient(.25em .25em at center center,#fff 50%,c.$mainColor 50%,transparent 70%);
}

.c-required{
    &::after{
        content: "*";
        color: c.$red;
    }
}

.c-section{
    &__area{
        padding: {
            top: 150px;
            bottom : 150px;
        };
        @include m.mq(sm) {
            padding: {
                top: 80px;
                bottom: 80px;
            };
        }
        &__wrapper{
            display: flex;
            flex-direction: row-reverse;
            margin-top: 50px;
            justify-content: space-between;
            align-items: center;
            @include m.mq(sm) {
                flex-direction: column-reverse;
            }
        }
        &__visual{
            max-width: 550px;
            width: 45.8%;
            border: 1px solid #ccc;
            background-color: #fff;
            @include m.mq(sm) {
                max-width: 550px;
                width: 100%;
                margin-bottom: 30px;
            }
        }
        &__block{
            max-width: 550px;
            width: 45.8%;
            @include m.mq(sm) {
                max-width: 550px;
                width: 100%;
            }
        }
        &__txt{
            font-size: min(1.8rem,1.5vw);
            @include m.mq(sm) {
                font-size: 1.6rem;
            }
        }
        &__list{
            margin-top: 4vw;
            >li{
                &:not(:last-child){
                    margin-bottom: 4vw;
                }
            }
            &__block{
                margin-top: 20px;
                &__ttl{
                    background: c.$mainColor;
                    color: #fff;
                    display: inline-block;
                    padding: 0.3em 0.6em;
                    font-size: min(2rem,1.7vw);
                    letter-spacing: 0.1em;
                    line-height: 1;
                    font-weight: 700;
                    @include m.mq(sm) {
                        font-size: max(1.6rem,2vw);
                    }
                }
                &__txt{
                    font-size: min(1.8rem,1.5vw);
                    margin-top: 0.3em;
                    letter-spacing: 0.1em;
                    &:not(:last-of-type){
                        margin-bottom: 2em;
                    }
                    @include m.mq(sm) {
                        font-size: 1.5rem;
                    }
                }
                &__cap{
                    margin-top: 1em;
                    font-size: min(1.2rem,1.1vw);
                    letter-spacing: 0.1em;
                    @include m.mq(sm) {
                        font-size: 1.3rem;
                    }
                }
                &__point{
                    font-size: min(1.8rem,1.5vw);
                    font-weight: 700;
                    letter-spacing: 0.1em;
                    margin-left: 2em;
                    @include m.mq(sm) {
                        font-size: clamp(3vw,1.2rem,3vw);
                    }
                }
            }
        }
    }//__area
}

//問い合わせボタンの各色の設定のmixin
@mixin list($data,$color:#ccc,$image:''){
    &[data-cat=#{$data}]{
        &::after{
            background-color: darken($color, 20%);
        }
        a{
            background-color: $color;
        }
        span{
            background-image: url($image);
        }
    }
}

.c-contact{
    position: relative;
    &:not(:last-child){
        margin-bottom: 10px;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        right: -4px;
        bottom: -4px;
        z-index: 0;
    }
    a{
        height: min(50px, 3.5em);
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.2em 0.7em;
        position: relative;
        z-index: 1;
        @include m.mq(tab-l) {
            justify-content: center;
        }
        &:hover{
            transform: translate(4px, 4px);
            opacity: 1;
        }
    }
    span{
        padding-left: 2.5em;
        background-position: center left;
        background-repeat: no-repeat;
        background-size: auto;
        color: #fff;
        font-weight: bold;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;
        height: 100%;
    }
    $names:  ('mail': c.$blue, 'tel':c.$yellow, 'line':c.$green, 'instagram':c.$red);
    @each $name,$color in $names {
        @include list(#{$name},$color,'../images/common/icon_#{$name}.svg');
    }

}


//wp-pagenation
.wp-pagenavi{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > span,
    > a{
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        color: #333;
        background-color: #fff;
        opacity: 1;
        border: 1px solid #ebebeb !important;
        transition: 0.3s;
        margin: 0 0.5em !important;
        border-radius: 100px;
        &.current,
        &:hover{
            background-color: c.$mainColor;
            border-color: inherit;
            color: #fff;
        }
    }
}


//edit
.c-edit{
    font-weight: 500;
    font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    line-height: 1.5;
    img{
        width: auto;
        display: block;
    }
    a{
        color: c.$mainColor;
        border-bottom: 1px solid c.$mainColor;
    }
    p{
        margin-bottom: 1.5em;
    }
    .alignright{
        float: right;
    }
    .alignleft{
        float: left;
    }
    .aligncenter{
        margin: 0 auto;
        clear: both;
    }
    ul{
        list-style: initial;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 2em;
    }
    ol{
        list-style: auto;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 2em;
    }
    blockquote{
        background-color: #eee;
        padding: 1em;
        margin: 1em 0;
    }

    h1{
        font-size: max(3rem,4vw);
        font-weight: 700;
        background-color: c.$mainColor;
        padding: 0.2em 0.5em;
        color: #fff;
        letter-spacing: 0.1em;
        text-align: left;
        margin: 10px 0 ;
    }
    h2{
        border-bottom: 2px solid #ccc;
        padding: 0.1em 0.5em;
        font-size: max(2.6rem,3.7vw);
        letter-spacing: 0.1em;
        line-height: 1.5;
        text-align: left;
        font-weight: 700;
        margin: 10px 0 ;
    }
    h3{
        border: 1px solid #ddd;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: max(2.4rem,3.4vw);
        font-weight: 700;
        padding: 0.2em 0.5em;
        // color: #fff;
        letter-spacing: 0.1em;
        text-align: center;
        margin: 10px 0 ;
    }
    h4{
        border-left: 5px solid c.$mainColor;
        padding: 0.1em 0.5em;
        font-size: max(2.2rem,3.0vw);
        letter-spacing: 0.1em;
        line-height: 1.5;
        text-align: left;
        font-weight: 700;
        margin: 10px 0 ;
    }
    
    h5{
        font-size: max(2.0rem,2.7vw);
        font-weight: 700;
        color: c.$mainColor;
        letter-spacing: 0.1em;
        text-align: left;
        margin: 10px 0 ;
    }
    h6{
        font-size: max(1.8rem,2.4vw);
        font-weight: 700;
        letter-spacing: 0.1em;
        text-align: left;
        margin: 10px 0 ;
    }
}



.grecaptcha-badge { visibility: hidden; }//reCHATCHAマークの非表示