@keyframes shake {
    0% { transform:translate(0,-50%)  rotate(-45deg)}
    5% { transform:translate(0,-50%)  rotate(-45deg)}
    10% { transform:translate(0,-50%) rotate(-45deg) }
    20% { transform:translate(-0.5em,-50%)  rotate(-45deg)}
    25% { transform:translate(0,-50%)  rotate(-45deg)}
    30% { transform:translate(-0.5em,-50%)  rotate(-45deg)}
    50% { transform:translate(0,-50%)  rotate(-45deg)}
    100% { transform:translate(0,-50%)  rotate(-45deg)}
}

@keyframes pulse {
    0% { transform:scale(1)}
    40% { transform:scale(1.1)}
    60% { transform:scale(1)}
    100% { transform:scale(1)}
}