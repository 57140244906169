@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;


.c-btn{
    &01{
        max-width: 500px;
        margin: 100px auto 0;
        height: 120px;
        position: relative;
        font-size: 2.4rem;
        @include m.mq(sm) {
            margin: 70px auto 0;
            height: 80px;
            font-size: 2.0rem;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            right: -6px;
            bottom: -6px;
            background-color: darken(c.$mainColor, 20%);
            z-index: 0;
        }
        a{
            background-color: c.$mainColor;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            letter-spacing: 0.1em;
            font-weight: 700;
            transition: 0.3s;
            position: relative;
            z-index: 1;
            height: 100%;
            &::before{
                content: "";
                width: 1em;
                height: 5px;
                background-color: #fff;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                position: absolute;
                top: calc(50% + 3px);
                right: calc(5% - 1px);
                @include m.mq(sm) {
                    height: 4px;
                    top: calc(50% + 2px);
                }
            }
            &::after{
                content: "";
                display: block;
                width: 0.65em;
                height: 0.65em;
                border-bottom: 5px solid #fff;
                border-right: 5px solid #fff;
                transform: rotate(-45deg) translateY(-50%);
                position: absolute;
                top: 50%;
                right: 3%;
                @include m.mq(sm) {
                    width: 0.5em;
                    height: 0.5em;
                }
            }
            &:hover{
                transform: translate(6px, 6px);
            }
        }
        
    }
    &02{
        max-width: 260px;
        width: 100%;
        margin: 20px auto 0;
        background-color: c.$mainColor;
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: inherit;
            color: #fff;
            font-size: inherit;
            line-height: 1;
            letter-spacing: 0.1em;
            font-weight: 700;
            padding: 0.5em;
            &::after{
                content: "";
                display: block;
                width: 0.7em;
                height: 0.7em;
                border-bottom: 1px solid #fff;
                border-right: 1px solid #fff;
                position: absolute;
                top: 50%;
                right: 1em;
                transform: rotate(-45deg) translateY(-50%);
                transform-origin: top;
            }
        }
    }
}