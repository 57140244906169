@charset "utf-8";
@use '../foundation/mixin' as m;
@use '../foundation/color' as c;

.c-inner {
  max-width: 1024px;
  width: 95%;
  margin: auto;
  position: relative;
  @include m.mq(tab-l) {
    width: 90%;
  }
}



$widths:  1500, 1200, 1100;
@each $width in $widths {
  .c-inner__#{$width} {
    max-width: #{$width}px;//変数$widthに単位つけるための記述
    width: 95%;
    margin:{
      left: auto;
      right: auto;
    }
    padding: 0px #{"min(3%,20px)"};//sassコンパイル時に単位の不整合をなくすための記述
    position: relative;
    // @include m.mq(sm) {
    //   padding: 70px #{"min(3%,20px)"};//sassコンパイル時に単位の不整合をなくすための記述
    // }
  }
}

